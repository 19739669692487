import {Route, Redirect, Switch} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'
import { AuthRequired } from "../../RivkinPages/authRequired";
import StaffApproval from '../../RivkinPages/StaffApprovals';

const Login = lazy(() => import('../../RivkinPages/Login'));
const Logout = lazy(() => import('../../RivkinPages/Logout'));

const Contacts = lazy(() => import('../../RivkinPages/Contacts'));
const Accounts = lazy(() => import('../../RivkinPages/Accounts'));
const Companies = lazy(() => import('../../RivkinPages/Companies'));
//const Providers = lazy(() => import('../../RivkinPages/Providers'));
const Trusts = lazy(() => import('../../RivkinPages/Trusts'));
const Wholesales = lazy(() => import('../../RivkinPages/Wholesales'));
const WholesalesTrading = lazy(() => import('../../RivkinPages/WholesalesTrading'));
const Multipliers = lazy(() => import('../../RivkinPages/Multipliers'));
const NavPrices = lazy(() => import('../../RivkinPages/NavPrices'));
const Positions = lazy(() => import('../../RivkinPages/Positions'));
const PerformanceFee = lazy(() => import('../../RivkinPages/PerformanceFee'));
const Documents = lazy(() => import('../../RivkinPages/Documents'));
const WholesalesKpi = lazy(() => import('../../RivkinPages/WholesalesKpi'));
const ReportTransactions = lazy(() => import('../../RivkinPages/Transactions'));
const Stocks = lazy(() => import('../../RivkinPages/Stocks'));
const ResetLink = lazy(() => import('../../RivkinPages/PasswordRecovery/ResetLink'));
const UpdatePassword = lazy(() => import('../../RivkinPages/PasswordRecovery/UpdatePassword'));

const AppMain = () => {

    return (
        <Fragment>

            {/* Rivkin */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-pulse-rise"/>
                        </div>
                        <h6 className="mt-5">
                            Loading...
                        </h6>
                    </div>
                </div>
            }>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/logout" component={Logout}/>

                    <Route exact path="/contacts/view" render={() => (
                        <AuthRequired redirectTo='/contacts/view' orRender={<Contacts match={{ url: "/contacts" }} />}
                    />)}/>
                    <Route exact path="/contacts/edit" render={() => (
                        <AuthRequired redirectTo='/contacts/edit' orRender={<Contacts match={{ url: "/contacts" }} />}
                    />)}/>
                    <Route exact path="/contacts/add" render={() => (
                        <AuthRequired redirectTo='/contacts/add' orRender={<Contacts match={{ url: "/contacts" }} />}
                    />)}/>
                    <Route exact path="/contacts" render={() => (
                        <AuthRequired redirectTo='/contacts' orRender={<Contacts match={{ url: "/contacts" }} />}
                    />)}/>
                    <Route exact path="/accounts" render={() => (
                        <AuthRequired redirectTo='/accounts' orRender={<Accounts match={{ url: "/accounts" }} />}
                    />)}/>
                    <Route exact path="/accounts/edit" render={() => (
                        <AuthRequired redirectTo='/accounts/edit' orRender={<Accounts match={{ url: "/accounts" }} />}
                    />)}/>
                    <Route exact path="/accounts/view" render={() => (
                        <AuthRequired redirectTo='/accounts/view' orRender={<Accounts match={{ url: "/accounts" }} />}
                    />)}/>

                    <Route exact path="/companies/add" render={() => (
                        <AuthRequired redirectTo='/companies/add' orRender={<Companies match={{ url: "/companies" }} />}
                    />)}/>
                    <Route exact path="/companies/view" render={() => (
                        <AuthRequired redirectTo='/companies/view' orRender={<Companies match={{ url: "/companies" }} />}
                    />)}/>
                    <Route exact path="/companies/edit" render={() => (
                        <AuthRequired redirectTo='/companies/edit' orRender={<Companies match={{ url: "/companies" }} />}
                    />)}/>
                    <Route exact path="/companies" render={() => (
                        <AuthRequired redirectTo='/companies' orRender={<Companies match={{ url: "/companies" }} />}
                    />)}/>

                    <Route exact path="/trusts" render={() => (
                        <AuthRequired redirectTo='/trusts' orRender={<Trusts match={{ url: "/trusts" }} />}
                    />)}/>
                    <Route exact path="/trusts/add" render={() => (
                        <AuthRequired redirectTo='/trusts/add' orRender={<Trusts match={{ url: "/trusts" }} />}
                    />)}/>
                    <Route exact path="/trusts/edit" render={() => (
                        <AuthRequired redirectTo='/trusts/edit' orRender={<Trusts match={{ url: "/trusts" }} />}
                    />)}/>
                    <Route exact path="/trusts/view" render={() => (
                        <AuthRequired redirectTo='/trusts/view' orRender={<Trusts match={{ url: "/trusts" }} />}
                    />)}/>

                    <Route exact path="/wholesales" render={() => (
                        <AuthRequired redirectTo='/wholesales' orRender={<Wholesales match={{ url: "/wholesales" }} />}
                    />)}/>
                    <Route exact path="/multipliers" render={() => (
                        <AuthRequired redirectTo='/multipliers' orRender={<Multipliers match={{ url: "/multipliers" }} />}
                    />)}/>
                    
                    <Route exact path="/wholesales-trading" render={() => (
                        <AuthRequired redirectTo='/wholesales-trading' orRender={<WholesalesTrading match={{ url: "/wholesales-trading" }} />}
                    />)}/>

                    <Route exact path="/wholesales-kpi" render={() => (
                        <AuthRequired redirectTo='/wholesales-kpi' orRender={<WholesalesKpi match={{ url: "/wholesales-kpi" }} />}
                    />)}/>

                    <Route exact path="/navprices" render={() => (
                        <AuthRequired redirectTo='/navprices' orRender={<NavPrices match={{ url: "/navprices" }} />}
                    />)}/>
                    <Route exact path="/navprices/list" render={() => (
                        <AuthRequired redirectTo='/navprices/list' orRender={<NavPrices match={{ url: "/navprices" }} />}
                    />)}/>

                    <Route exact path="/positions" render={() => (
                        <AuthRequired redirectTo='/positions' orRender={<Positions match={{ url: "/positions" }} />}
                    />)}/>
                    <Route exact path="/positions/list" render={() => (
                        <AuthRequired redirectTo='/positions/list' orRender={<Positions match={{ url: "/positions" }} />}
                    />)}/>
                    <Route exact path="/positions/listfull" render={() => (
                        <AuthRequired redirectTo='/positions/listfull' orRender={<Positions match={{ url: "/positions" }} />}
                    />)}/>
                    <Route exact path="/positions/close" render={() => (
                        <AuthRequired redirectTo='/positions/close' orRender={<Positions match={{ url: "/positions" }} />}
                    />)}/>
                    <Route exact path="/positions/edit" render={() => (
                        <AuthRequired redirectTo='/positions/edit' orRender={<Positions match={{ url: "/positions" }} />}
                    />)}/>
                    <Route exact path="/positions/add" render={() => (
                        <AuthRequired redirectTo='/positions/add' orRender={<Positions match={{ url: "/positions" }} />}
                    />)}/>

                    <Route exact path="/stocks" render={() => (
                        <AuthRequired redirectTo='/stocks' orRender={<Stocks match={{ url: "/stocks" }} />}
                    />)}/>
                    <Route exact path="/stocks/list" render={() => (
                        <AuthRequired redirectTo='/stocks/list' orRender={<Stocks match={{ url: "/stocks" }} />}
                    />)}/>

                    <Route exact path="/staff-approvals" render={() => (
                        <AuthRequired redirectTo='/staff-approvals' orRender={<StaffApproval match={{ url: "/staff-approvals" }} />}
                    />)}/>
                    <Route exact path="/staff-approvals/add" render={() => (
                        <AuthRequired redirectTo='/staff-approvals/add' orRender={<StaffApproval match={{ url: "/staff-approvals" }} />}
                    />)}/>
                    
                    <Route exact path="/performancefee" render={(props) => (
                        <AuthRequired redirectTo='/performancefee' orRender={<PerformanceFee match={{ url: "/performancefee" }} />}
                        
                    />)}/>
                    <Route exact path="/performancefee/list" render={() => (
                        <AuthRequired redirectTo='/performancefee/list' orRender={<PerformanceFee match={{ url: "/performancefee/list" }} />}
                    />)}/>

                    <Route exact path="/documents" render={() => (
                        <AuthRequired redirectTo='/documents' orRender={<Documents match={{ url: "/documents" }} />}
                    />)}/>

                    <Route exact path="/documents/add" render={() => (
                        <AuthRequired redirectTo='/documents/add' orRender={<Documents match={{ url: "/documents" }} />}
                    />)}/>

                    <Route exact path="/documents/edit" render={() => (
                        <AuthRequired redirectTo='/documents/edit' orRender={<Documents match={{ url: "/documents" }} />}
                    />)}/>

                    <Route exact path="/documents/view" render={() => (
                        <AuthRequired redirectTo='/documents/view' orRender={<Documents match={{ url: "/documents" }} />}
                    />)}/>

                    <Route exact path="/report-transactions" render={() => (
                        <AuthRequired redirectTo='/report-transactions' orRender={<ReportTransactions match={{ url: "/report-transactions" }} />}
                    />)}/>

                    <Route path="/reset-password-link" component={ResetLink}/>
                    <Route path="/password/reset/:user_id/:user_token" component={UpdatePassword}/>

                </Switch>
            </Suspense>

            <Route exact path="/" render={() => (
                <Redirect to="/contacts"/>
            )}/>

        </Fragment>
    )
};

export default AppMain;
