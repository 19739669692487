export function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    //console.log(filter);
    return (
        row[id] !== undefined ?
            String(String(row[id]).toLowerCase()).includes(filter.value.toLowerCase())
        :
            true
    );
}

export function filterCaseInsensitiveFromThirdChar(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] !== undefined && filter.value.length > 2 ?
            String(String(row[id]).toLowerCase()).includes(filter.value.toLowerCase())
        :
            true
    );
}
