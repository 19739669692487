import moment from 'moment';

// FIX!
export function sortMethodAmount(a, b) {
    let substringA = a.toString().substring(1);
    let substringB = b.toString().substring(1);
    
    let auxA = ( substringA == 0 || isNaN( substringA ) ) ? -Infinity : substringA;
    let auxB = ( substringB == 0 || isNaN( substringB ) ) ? -Infinity : substringB;
    //let auxA = ( a.substring(1) == 0 || isNaN( a.substring(1) ) ) ? -Infinity : a.substring(1);
    //let auxB = ( b.substring(1) == 0 || isNaN( b.substring(1) ) ) ? -Infinity : b.substring(1);
    return auxA - auxB;
}

// TEST!!!
export function sortMethodAmountNumberFormat(a, b) {
  let auxA = ( !a || !a.props || isNaN( a.props.value ) ) ? -Infinity : a.props.value;
  let auxB = ( !b || !b.props || isNaN( b.props.value ) ) ? -Infinity : a.props.value;
  return auxA - auxB;
}

export function sortMethodDate(a, b) {
    let auxA = a==='' || a==='-' ? '01-01-1900' : a;
    let auxB = b==='' || b==='-' ? '01-01-1900' : b;

    if (moment(auxA,'DD-MM-YYYY').isAfter(moment(auxB,'DD-MM-YYYY'))){
      return 1;
    }else {
      return -1
    }
}

export function sortMethodNumber(a, b) {
  let auxA = ( parseFloat(a) == 0 || isNaN( parseFloat(a) ) ) ? -Infinity : parseFloat(a);
  let auxB = ( parseFloat(b) == 0 || isNaN( parseFloat(b) ) ) ? -Infinity : parseFloat(b);
  return auxA - auxB;
}
