
import { CheckUserPermissionExists } from '../../RivkinPages/utils/utils';

export const  addInMainNav = () => {

    console.log("MainNav called");
    let MainNav = [];

    if (CheckUserPermissionExists('Menu.ContactsView') == true){
        MainNav.push({
            icon: 'pe-7s-id',
            label: 'Contacts',
            to: '#/contacts/'
        });
    }

    if (CheckUserPermissionExists('Menu.AccountsView') == true){
        MainNav.push({
            icon: 'pe-7s-user',
            label: 'Accounts',
            to: '#/accounts/'
        });
    }

    if (CheckUserPermissionExists('Menu.CompaniesView') == true){
        MainNav.push({
            icon: 'pe-7s-users',
            label: 'Companies',
            to: '#/companies/'
        });
    }

    if (CheckUserPermissionExists('Menu.TrustsView') == true){
        MainNav.push({
            icon: 'pe-7s-wallet',
            label: 'Trusts',
            to: '#/trusts/'
        });
    }

    if (CheckUserPermissionExists('Menu.WholesalesView') == true){
        MainNav.push({
            icon: 'pe-7s-display2',
            label: 'Wholesales',
            content: [
                {
                    icon: 'pe-7s-display2',
                    label: 'Wholesales Report',
                    to: '#/wholesales/'
                },
                {
                    icon: 'pe-7s-display2',
                    label: "Account's Multipliers",
                    to: '#/multipliers/'
                },
                {
                    icon: 'pe-7s-display2',
                    label: "Wholesales Trading",
                    to: '#/wholesales-trading/'
                },
                {
                    icon: 'pe-7s-display2',
                    label: "Wholesales Kpi Report",
                    to: '#/wholesales-kpi/'
                }
            ]
        });
    }

    if (CheckUserPermissionExists('Menu.PerformanceFeeView') == true){
        MainNav.push({
            icon: 'pe-7s-display1',
            label: 'Performance Fee',
            to: '#/performancefee/'
        });
    }

    if (CheckUserPermissionExists('Menu.StaffTradesView') == true){
        MainNav.push({
            icon: 'pe-7s-mail',
            label: 'Staff Trades',
            to: '#/staff-approvals/'
        });
    }

    if (CheckUserPermissionExists('Menu.PositionsView') == true){
        MainNav.push({
            icon: 'pe-7s-graph1',
            label: 'Positions',
            content: [
                {
                    icon: 'pe-7s-graph1',
                    label: 'Positions',
                    to: '#/positions/'
                },
                {
                    icon: 'pe-7s-graph1',
                    label: "Stocks",
                    to: '#/stocks/'
                }
            ]
        });
    }



    if (CheckUserPermissionExists('Menu.DocumentsView') == true){
        MainNav.push({
            icon: 'pe-7s-browser',
            label: 'Documents',
            to: '#/documents'
        });
    }

    if (CheckUserPermissionExists('Menu.NavPricesView') == true){
        MainNav.push({
            icon: 'pe-7s-graph2',
            label: 'Nav Prices',
            to: '#/navprices'
        });
    }

    if (CheckUserPermissionExists('Menu.ReportTransactions') == true){
        MainNav.push({
            icon: 'pe-7s-wallet',
            label: 'Report Transactions',
            to: '#/report-transactions/'
        });
    }

    return MainNav;
};

export const MainNav = addInMainNav();


export const ComponentsNav = [
    
];
export const FormsNav = [
    
];
export const WidgetsNav = [
    
];
export const ChartsNav = [
    
];