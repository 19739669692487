import React, {Fragment} from 'react';
import { Row, Col, Card, CardBody, Alert } from 'reactstrap';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import RivkinApiClient from '../../rivkinApiClient';
import { filterCaseInsensitive, filterCaseInsensitiveFromThirdChar } from '../../tableFilters';
import {Button} from 'reactstrap';
import { Link, Redirect } from "react-router-dom";
import Configuration from '../../configuration';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import ReactTable from "react-table";
import { sortMethodAmount, sortMethodDate } from '../../tableOrders';
import { CheckUserPermissionExists } from '../../utils/utils';

export default class StaffApprovalList extends React.Component {
    constructor() {
        super();
        this.state = { data: null, user: {}, request: {} };
        this.config = new Configuration();
        this.retrieveData = this.retrieveData.bind(this);

        // this.state.staffAdminsIds = this.config.STAFF_ADMIN_IDS.split(", ").map(function (x) { 
        //     return parseInt(x, 10); 
        //   });
        // console.log(this.state.staffAdminsIds);
        console.log(JSON.parse( localStorage.getItem('user') ).id);
    }

    componentWillMount() {
        this.setState({ user: JSON.parse( localStorage.getItem('user') ), redirect: false });
    }

    componentDidMount() {
        this.retrieveData();
    }

    retrieveData = () => {
        let apiClient = new RivkinApiClient();
        apiClient.get('staff-approval').then(res => {
            this.setState({ data: res.data });
        });
    }

    approve = ( order ) => {
        let apiClient = new RivkinApiClient();
        apiClient.put('staff-approval/' + order.order_id, { approval_user_id: this.state.user.id }).then(res => {

            if (res.hasOwnProperty('error'))
                this.setState({ errors: res.error });
            else {
                localStorage.setItem('success', 'Request was submitted successfully!');
                this.setState({ redirect: true });
            }

        })
        .catch(err => {
            console.error(err);
            this.setState({ errors: [err] });
        })
        .finally( ()=> {
            this.retrieveData();
        } );
    }
    cancel= ( order ) => {
        let apiClient = new RivkinApiClient();
        apiClient.delete('staff-approval/' + order.order_id, { approval_user_id: this.state.user.id }).then(res => {

            if (res.hasOwnProperty('error'))
                this.setState({ errors: res.error });
            else {
                localStorage.setItem('success', 'Request was submitted successfully!');
                this.setState({ redirect: true });
            }

        })
        .catch(err => {
            console.error(err);
            this.setState({ errors: [err] });
        })
        .finally( ()=> {
            this.retrieveData();
        } );
        
    }
    render() {
        const {data} = this.state;

        const colorFill = (state, rowInfo, column) => {
            return {
                style: {
                    background: rowInfo && rowInfo.row.state == 'Pending approval' ? '#FFEB9C' : null,
                },
            };
        };

        if (data == null)
            return (<div className="text-center"><h6 className="mt-5">Loading...</h6></div>);

        let success = localStorage.getItem('success');
        if (success)
            localStorage.removeItem('success');

        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                    <div>
                        <PageTitle
                            heading="Staff Approvals"
                            subheading=""
                            icon="pe-7s-user icon-gradient bg-tempting-azure"
                        />
                    </div>
                    { success ? (<Alert color="success">{success}</Alert>) : null }
                    
                    <Row>
                        <Col md="12" className="mb-3">
                            <Link className="btn btn-lg btn btn-primary" to={"/staff-approvals/add"}>New Order</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card className="main-card mb-3">
                                <CardBody>
                                    <ReactTable
                                        data={data}
                                        defaultSorted={[{ id: 'created_date', desc: true }]}
                                        columns={[
                                            {
                                                columns: [
                                                    {
                                                        Header: "Requester",
                                                        id: "user_id",
                                                        accessor: d => d.sa_user.name,
                                                        width: 140,
                                                        getProps: colorFill
                                                    },
                                                    {
                                                        Header: "Account",
                                                        id: "account",
                                                        accessor: d => d.account,
                                                        getProps: colorFill
                                                        // ,filterMethod: filterCaseInsensitiveFromThirdChar
                                                    },
                                                    {
                                                        Header: "Type",
                                                        id: "type",
                                                        accessor: d => d.type,
                                                        getProps: colorFill
                                                    },
                                                    {
                                                        Header: "Instrument",
                                                        id: "instrument",
                                                        accessor: d => d.instrument,
                                                        getProps: colorFill
                                                    },
                                                    {
                                                        Header: "Units",
                                                        id: "units",
                                                        accessor: d => <NumberFormat value={d.units} displayType={'text'} thousandSeparator={true} prefix={' '} />,
                                                        getProps: colorFill,
                                                        //sortMethod: sortMethodAmount
                                                        sortable: false
                                                    },
                                                    {
                                                        Header: "Request Date",
                                                        id: "created_date",
                                                        //accessor: d => (new Date(d.created_date)).toLocaleDateString('en-AU'),
                                                        accessor: d => d.created_date ? moment(d.created_date).format('DD-MM-YYYY') : '-',
                                                        getProps: colorFill,
                                                        sortMethod: sortMethodDate
                                                    },
                                                    {
                                                        Header: "Reviewer",
                                                        id: "approval_user_id",
                                                        accessor: d => d.sa_approval_user ? d.sa_approval_user.name : '-',
                                                        getProps: colorFill
                                                    },
                                                    {
                                                        Header: "Review Date",
                                                        id: "approval_date",
                                                        //accessor: d => d.approval_date ? (new Date(d.approval_date)).toLocaleDateString('en-AU') : '-',
                                                        accessor: d => d.approval_date ? moment(d.approval_date).format('DD-MM-YYYY') : '-',
                                                        getProps: colorFill,
                                                        sortMethod: sortMethodDate
                                                    },
                                                    {
                                                        Header: "Status",
                                                        id: "state",
                                                        accessor: d => d.state,
                                                        getProps: colorFill
                                                    },
                                                    {
                                                        Header: '',
                                                        filterable: false,
                                                        width: 200,
                                                        getProps: colorFill,
                                                        Cell: row => (
                                                            <div className="button-container">
                                                                {/* { this.state.staffAdminsIds.includes( JSON.parse( localStorage.getItem('user') ).id ) && */}
                                                                { CheckUserPermissionExists('StaffTradesApprover') &&
                                                                <Button color="primary" disabled={ row.original.state == 'Approved' ? 'disabled':'' } onClick={ () => this.approve( row.original )} value={row.original} >Approve</Button>
                                                                }
                                                                {/* { this.state.staffAdminsIds.includes( JSON.parse( localStorage.getItem('user') ).id ) && */}
                                                                { CheckUserPermissionExists('StaffTradesApprover') &&
                                                                <Button color="danger" disabled={ row.original.state == 'Declined' ? 'disabled':'' } onClick={ () => this.cancel( row.original )}>Decline</Button>
                                                                }
                                                            </div>

                                                        )
                                                     }
                                                ]
                                            },
                                        ]}
                                        defaultPageSize={10}
                                        className="-striped -highlight"
                                        filterable
                                        defaultFilterMethod={filterCaseInsensitive}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}
