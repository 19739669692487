import React, { Fragment } from 'react';
import { Row, Col, Card, CardBody, Button, Form, FormGroup, Label, Input, Alert, InputGroup, InputGroupAddon, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import RivkinApiClient from '../../rivkinApiClient';
import { Link, Redirect } from "react-router-dom";
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';

import ReactTable from "react-table";

import { filterCaseInsensitive } from '../../tableFilters';

const moment = require('moment');

const queryString = require('query-string');

export default class StaffApprovalAdd extends React.Component {
    constructor() {
        super();
        this.state = {
            account: null,
            type: 'BUY',
            instrument: null,
            units: null,
            description: '',
            redirect: false,
            errors: null,
            user: null
        };
        // this.handleBirthdateChange = this.handleBirthdateChange.bind(this);
        // this.handleBalanceLastUpdateChange = this.handleBalanceLastUpdateChange.bind(this);
        // this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillMount() {
        this.setState({ user: JSON.parse( localStorage.getItem('user') ) });
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
          [name]: value
        });
        console.log(`value=${value} name=${name}`);
    }

    toggleActive = () => {
        this.setState({
          active: !this.state.active,
        });
    }

    // handleBirthdateChange(date) { this.setState({ birthdate: moment(date).format("YYYY-MM-DD") }); }
    // handleBalanceLastUpdateChange(date) { this.setState({ smsf_balance_last_update: moment(date).format("YYYY-MM-DD") }); }

    handleSubmit = () => {

        this.setState({ errors: null });

        let apiClient = new RivkinApiClient();

        if (!this.state.account) {
            this.setState({ errors: ['Account is required.'] });
            return;
        }
        if (!this.state.type) {
            this.setState({ errors: ['Type is required.'] });
            return;
        }
        if (!this.state.instrument) {
            this.setState({ errors: ['Instrument is required.'] });
            return;
        }
        if (!this.state.units ) {
            this.setState({ errors: ['Units are required.'] });
            return;
        }

        let approval = {
            user_id: this.state.user.id,
            account: this.state.account,
            type: this.state.type,
            instrument: this.state.instrument,
            units: this.state.units,
            description: this.state.description,
        };

        apiClient.post('staff-approval', approval).then(res => {

            if (res.hasOwnProperty('error'))
                this.setState({ errors: res.error });
            else {
                localStorage.setItem('success', 'Request was submitted successfully!');
                this.setState({ redirect: true });
            }

        })
        .catch(err => {
            console.error(err);
            this.setState({ errors: [err] });
        });
    }

    render() {

        if (this.state.redirect) {
            return <Redirect to='/staff-approvals'/>;
        }

        return (
            <Fragment>
                <div>
                    <PageTitle
                        heading="Request Form"
                        subheading="Submit a new request"
                        icon="pe-7s-id icon-gradient bg-tempting-azure"
                    />
                </div>
                { this.state.errors ? (<Alert color="danger">{this.state.errors.map(function(error, index) { return (<li key={index}>{error}</li>)})}</Alert>) : null }
                <Card className="main-card mb-3">
                    <CardBody>
                        <Form>
                            <FormGroup row>
                                <Label for="account" sm={2}>Account</Label>
                                <Col sm={10}>
                                    <Input type="text" name="account" id="account" maxLength="50" onChange={this.handleInputChange} value={this.state.account ? this.state.account : ''} />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="type" sm={2}>Type</Label>
                                <Col sm={10}>
                                    <Input type="select" name="type" id="type" value={this.state.type ? this.state.type : ''} onChange={this.handleInputChange}>
                                        <option value={"BUY"} >{"BUY"}</option>
                                        <option value={"SELL"} >{"SELL"}</option>
                                    </Input>
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="instrument" sm={2}>Instrument</Label>
                                <Col sm={10}>
                                    <Input type="text" name="instrument" id="instrument" maxLength="50" onChange={this.handleInputChange} value={this.state.instrument ? this.state.instrument : ''} />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="units" sm={2}>Units</Label>
                                <Col sm={10}>
                                    <Input type="text" name="units" id="units" maxLength="1000000" onChange={this.handleInputChange} value={this.state.units ? this.state.units : ''} />
                                </Col>
                            </FormGroup>

                            <FormGroup row>
                                <Label for="description" sm={2}>Description</Label>
                                <Col sm={10}>
                                    <Input type="text" name="description" id="description"  onChange={this.handleInputChange} value={this.state.description ? this.state.description : ''} />
                                </Col>
                            </FormGroup>

                        </Form>

                        <Row className="mt-3">
                            <Col>
                                <Button color="primary" onClick={this.handleSubmit} size="lg">Submit</Button>
                                <Link to={"/staff-approvals"} className="btn btn-lg btn btn-info ml-3">Cancel</Link>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

            </Fragment>
        );
    }
}
